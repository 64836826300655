.double-button-modal .react-responsive-modal-modal {
  width: 540px;
  padding: 32px 40px;
  border-radius: 16px;
}

@media (max-width: 575.98px) {
  .double-button-modal .react-responsive-modal-modal  {
    padding: 24px;
  }
}
