.simple-modal .react-responsive-modal-modal {
  width: 540px;
  padding: 32px 40px;
  border-radius: 16px;
}

.simple-modal h2 {
  margin-bottom: 15px;
}

.simple-modal .code {
  opacity: 0.5;
  margin-bottom: 25px;
}

@media (max-width: 575.98px) {
  .simple-modal .react-responsive-modal-modal  {
    padding: 24px;
  }
}
